<template>
  <div>
    <el-upload
      ref="uploadImage"
      name="uploadImage"
      :action="''"
      class="image-uploader"
      :show-file-list="false"
      :auto-upload="false"
      :on-change="handleChange"
      :disabled="disabled">
      <img v-if="imageUrl" :src="imageUrl" class="ImageShow"  @load="loadImage">
      <i v-else class="el-icon-plus image-uploader-icon"></i>
      <div class="upload-loading" v-loading="loading"></div>
    </el-upload>

    <el-dialog :close-on-click-modal="false" :visible.sync="cropperModel"  width="800px" :before-close="beforeClose">
      <Cropper :img-file="file" ref="vueCropper" :cropWidth="cropWidth" :cropHeight="cropHeight" @upload="upload">
      </Cropper>
     </el-dialog>
  </div>
</template>

<script>
import {uploadImage} from '@/api/app'
import Cropper from '@/components/Cropper'

export default {
  name: 'ImagegUpload',
  components: {
    Cropper
  },
  props:{
    value: {
      type: String,
      default: ''
    },
    cropWidth: {
      // 单图剪裁框宽度
      type: Number,
      default: 230
    },
    cropHeight: {
      // 单图剪裁框高度
      type: Number,
      default: 153
    }
  },
  data() {
    return {
      tempUrl: '',
      disabled:false,
      cropperModel: false, // 剪裁组件弹窗开关
      file:'',
      loading: false
    }
  },
  updated () {
    if (this.$refs.vueCropper) {
      this.$refs.vueCropper.Update()
    }
  },
  computed: {
    imageUrl() {
      return this.value
    }
  },
  methods: {
    handleChange(file, fileList) {
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 15;
      if (fileList.length > 0) {
      //单个覆盖上传
        this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的csv文件
        if (!isJPG && !isPNG) {
          this.$message.error('上传头像图片只能是 JPG、PNG格式!')
          return 
        }
        if (!isLt1M) {
          this.$message.error('上传头像图片大小不能超过 15MB!')
          return
        }
        
        this.$nextTick(() => {
          var reader = new FileReader();
          reader.readAsDataURL(file.raw)
          reader.onload = e => {
            let data = e.target.result
            file.url = data
            this.file = file
            this.cropperModel = true
          }
        })
      }
    },
    // 自定义upload事件
    upload (data) {
      const file = new window.File([data],'image.jpg')
      let formData = new FormData();
      formData.set("file", file);
      this.disabled = true
      this.loading = true
      uploadImage(formData).then(res => {
        this.$emit('input', res.data.result)
      }).catch(error => {
        this.disabled = false
        this.loading = false
        this.$message.error('上传失败')
      })
      this.cropperModel = false
    },
    loadImage() {
      this.loading = false
      this.disabled = false
    },
    beforeClose (done) {
      this.cropperModel = false
    }
  }
}
</script>

<style lang="scss">
.image-uploader {
  width:230px;
  height:153px;
  line-height:153px;
  .el-upload {
    width:100%;
    height:100%;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    -webkit-box-sizing: content-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409EFF;
  }
  .image-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    text-align: center;
  }
  .ImageShow {
    width: 100%;
    height: 100%;
    display: block;
  }
  .upload-tip{
    float:left;
  }
  .upload-loading{
    width: 100%;
    height: 100%;
    position:absolute !important;
    left:0;
    top:0;
    .el-loading-spinner{
      margin-top:0;
      top:10%;
    }
  }
}
</style>