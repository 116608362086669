import request from '@/utils/request'



// 图片上传
export function uploadImage(data) {
  return request({
    url: '/api/content/uploadImage',
    headers: {
      "Content-type": "multipart/form-data"
    },
    method: 'post',
    data
  })
}
